import axios from 'axios';

let currentController = null;
export default {
  // eslint-disable-next-line consistent-return
  async getOrganizationsWithPagination(pageNumber, pageSize, nameFilter, organizationTypeIdFilter, isLocalNsiFilter, sortField, sortDesc) {
    // Отменяем предыдущий запрос, если он существует
    if (currentController) {
      currentController.abort();
    }
    currentController = new AbortController();
    const { signal } = currentController;
    const response = await axios.get('/api/Organizations', {
      params: {
        pageNumber,
        pageSize,
        nameFilter,
        organizationTypeIdFilter: organizationTypeIdFilter.length > 0 ? organizationTypeIdFilter.join('!;!') : '',
        isLocalNsiFilter,
        sortField,
        sortDesc
      }
    }, { signal });
    currentController = null;
    return response;
  },
  getOrganization(id) {
    return axios.get('/api/Organizations/GetOrganization', {
      params: {
        id
      }
    });
  },
  getOrganizationSearchByNameAndRole(roleName) {
    return axios.get(`/api/Organizations/SearchByNameAndRole?roleName=${roleName}`);
  },
  addOrganization: (organization) => axios.post('/api/organizations', organization),
  updateOrganization: (organization) => axios.put(`/api/organizations/${organization.id}`, organization),
  deleteOrganization: (id) => axios.delete(`/api/organizations/${id}`),
  mergeOrganizations(mainId, secondaryIds) {
    return axios.post('/api/Organizations/Merge', { mainId, secondaryIds });
  },
  getRelatives: () => axios.get('/api/organizations/GetRelatives'),
};

