<template>
  <div class="my-flex-container-column">
    <el-row class="m-0" :gutter="20">
      <el-col v-if="userCanEdit" :span="3">
        <el-button @click="handleCreate" type="text" icon="el-icon-circle-plus-outline" :disabled="mergeMode">Добавить</el-button>
      </el-col>
      <el-col :span="3">
      <el-button @click="handleDownLoad" type="text"
                  :loading="loading"
                   icon="el-icon-download">Выгрузить
        </el-button>
      </el-col>
      <el-col v-if="userCanEdit" :span="3">
        <el-button @click="handleMergeMode" type="text" icon="el-icon-share">Объединить</el-button>
      </el-col>
      <el-col v-if="userCanEdit && mergeMode" :span="3">
        <el-button @click="handleMergeModeCancel" type="text" icon="el-icon-circle-close">Отменить</el-button>
      </el-col>
      <el-col :span="12">
        <pagination style="text-align: center"
                    :total-count="totalCount"
                    :page-size.sync="pageSize"
                    :current-page.sync="pageNumber"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange" />
      </el-col>
    </el-row>
    <div>
      <!-- <el-button @click="toggleAllNodes" type="text" class="ml-2">Свернуть/развернуть всё</el-button> -->
      <span class="total_items">Всего: {{this.organizationsCount}}</span>
    </div>
    <div v-loading="loading" class="my-flex-data-content">
      <!-- <infinite-tree
        ref="tree"
        :data="organizations"
        @on-view-click="handleView"
        @on-check-click="handleCheck"
      >
        <div class="infinite-tree-node" slot-scope="{ node }">{{ node.color }}</div>
      </infinite-tree> -->
      
      <el-table v-loading="loading"
                ref="treeTable"
                :data="organizations"
                style="width: 100%"
                row-key="id"
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                default-expand-all>
        <el-table-column class-name="orgTableColumnNsi"
                         prop="name"
                         label="Наименование"
                         sortable>
          <template slot-scope="scope">
            <span>
              <el-button type="text"
                         icon="el-icon-search"
                         style="margin-right:.4rem; margin-left: 3px; vertical-align: middle"
                         v-if="!mergeMode"
                         size="mini"
                         @click="handleView(scope.$index, scope.row)">
              </el-button>
              <el-checkbox v-if="mergeMode"
                           v-model="scope.row.isMerging"
                           style="margin-right:.8rem"
                           @change="handleIsMergingChange(scope.row)">
              </el-checkbox>
              <!-- <el-button v-if="scope.row.parentId && userCanEdit" style="margin-right:.8rem" type="text" icon="el-icon-edit"
                        @click="handleEdit(scope.$index, scope.row)"></el-button> -->
            </span>
            <span>{{scope.row.name}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="organizationTypeName"
                         label="Тип"
                         min-width="30"
                         sortable>
        </el-table-column>
        <el-table-column prop="isAip"
                         label="СК-11"
                         width="80"
                         align="center"
                         header-align="center"
                         sortable
                         :formatter="boolRenderer">
        </el-table-column>
        <el-table-column v-if="userCanEdit"
                         label=""
                         width="50">
          <template slot-scope="scope">
            <el-button type="text"
                       icon="el-icon-delete"
                       :disabled="scope.row.isAip"
                       @click="handleDelete(scope.$index, scope.row)">
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <organization-modal @on-update="getOrganizations()" @on-create="getOrganizations()" @on-delete="getOrganizations()"
                        v-model="dialogVisible" :organization="dialogOrganization"></organization-modal>
    <organizations-merge-modal v-model="dialogMergeVisible" :items="mergingItems" @on-merge="handleMerge($event)" @on-merge-item-remove="handleMergeItemRemove($event)"></organizations-merge-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import organizationsApi from '@/api/nsi/organizations';
import pagination from '@/components/Ui/Pagination';
import formatters from '@/mixins/formatters.js';
import xlsUpDownHelper from '@/mixins/xlsUpDownHelper';
import reportsApi from '@/api/reports';
import io from '@/utilities/fileIO';
// import InfiniteTree from '@/components/Ui/InfiniteTree/InfiniteTree';
import OrganizationModal from './OrganizationModal';
import OrganizationsMergeModal from './OrganizationsMergeModal';


export default {
  name: 'OrganizationsTable',
  props: {
    filterModel: { type: Object, default: () => {} },
    filterActive: { type: Boolean, default: () => true },
  },
  mixins: [formatters, xlsUpDownHelper],
  components: {
   // InfiniteTree, 
   pagination, OrganizationModal, OrganizationsMergeModal
  },
  data() {
    return {
      loading: false,
      organizations: [],
      pageNumber: 1,
      pageSize: 50,
      totalCount: 0,
      sortField: 'name',
      sortDesc: false,
      dialogVisible: false,
      dialogOrganization: {},
      mergeMode: false,
      mergingItems: [],
      dialogMergeVisible: false,
      organizationsCount: 0
    };
  },
  computed: {
    ...mapGetters('identity', ['userHasRole']),
    userCanEdit() {
      return this.userHasRole('NsiCorpEdit,NsiFullAccess');
    },
    treeContainer() {
      return this.$refs.treeTable;
    }
  },
  watch: {
    filterModel: {
      handler() {
        this.getOrganizations();
      },
      deep: true
    },
  },
  async mounted() {
    // this.$nextTick(() => {
    //   this.tableHeight = this.getTableHeight();
    // });
    await this.getOrganizations();
   // this.tableHeight = this.getTableHeight();
  },
  methods: {
    async handleDownLoad() {
      this.loading = true;
      const params = {
        nameFilter: this.filterModel.nameFilter,
        organizationTypeIdFilter: this.filterModel.organizationTypeIdFilter.length > 0 ? this.filterModel.organizationTypeIdFilter.join('!;!') : '',
        isLocalNsiFilter: this.filterModel.isLocalNsiFilter
      };
      const res = await reportsApi.createOrganizationsReport(params);
      if (res.data.exceptionDetails) {
        this.$alert(`При формировании отчета произошла ошибка: ${res.data.exceptionDetails}`, 'Ошибка');
      } else {
        io.downloadFile(res.data.content, res.data.contentType, res.data.fileName);
      }
      this.loading = false;
    },
    async getOrganizations() {
      this.loading = true;
      const res = await organizationsApi.getOrganizationsWithPagination(
          this.pageNumber,
          this.pageSize,
          this.filterModel.nameFilter,
          this.filterModel.organizationTypeIdFilter,
          this.filterModel.isLocalNsiFilter,
          this.sortField,
          this.sortDesc
      );
      if (res.data) {
        this.organizations = res.data.items;
        this.totalCount = res.data.totalCount;
        this.organizationsCount = res.data.treeItemsCount;

        // this.treeContainer.tree.loadData(this.organizations);
        // this.applyIsMerging(this.mergeMode);
      }
      this.loading = false;
    },
    async handleSizeChange() {
      this.pageNumber = 1;
      await this.getOrganizations();
    },
    async handleCurrentChange() {
      await this.getOrganizations();
    },
    async handleSortChange(event) {
      if (event.order === null) {
        this.sortDesc = null;
        this.sortField = null;
      } else {
        this.sortDesc = event.order === 'descending';
        this.sortField = event.prop;
      }
      await this.getOrganizations();
    },
    // async handleView(node) {
    //   const res = await organizationsApi.getOrganization(node.id);
    //   this.dialogOrganization = res.data;
    //   this.dialogVisible = true;
    // },
    async handleView(index, row) {
      const res = await organizationsApi.getOrganization(row.id);
      this.dialogOrganization = res.data;
      this.dialogVisible = true;
    },
    handleIsMergingChange(item) {
      if (!this.mergingItems.some((x) => x.id === item.id)) {
        this.mergingItems.push(item);
      } else {
        const index = this.mergingItems.findIndex((x) => x.id === item.id);
        if (index > -1) {
          this.mergingItems.splice(index, 1);
        }
      }
    },
    handleCreate() {
      this.dialogOrganization = {
        id: null, name: null, parentId: null, analysisIds: null
      };
      this.dialogVisible = true;
      this.$root.$emit('addEvent', {
        editMode: true,
      });
    },
    handleDelete(index, org) {
      this.$confirm('Вы действительно хотите удалить запись?', 'Удаление записи!', {
        confirmButtonText: 'ОК',
        cancelButtonText: 'Отмена',
        type: 'warning',
      }).then(async () => {
        this.loading = true;

        const res = await organizationsApi.deleteOrganization(org.id);
        if (!res.data.succeeded) {
          this.$alert(res.data.resultMessage, 'Ошибка', {
            confirmButtonText: 'OK',
            type: 'warning',
            customClass: 'alert-30',
          });
        } else {
          await this.getOrganizations();
        }

        this.loading = false;
      });
    },
    // applyIsMerging(isMerging) {
    //   const { nodes } = this.treeContainer.tree;

    //   nodes.forEach((node) => {
    //     if (isMerging && this.mergingItems.some((t) => t.id === node.id)) {
    //       node.state.checked = true;
    //     }

    //     if (!isMerging) node.state.checked = false;

    //     node.сheckAllowed = isMerging;
    //     node.viewAllowed = !isMerging;
    //   });

    //   this.treeContainer.rerender();
    // },
    applyIsMerging() {
      for (let i = 0; i < this.organizations.length; i++) {
        const item = this.organizations[i];
        this.$set(item, 'isMerging', this.mergingItems.some((x) => x.id === item.id));
      }
    },
    handleMergeItemRemove(item) {
      const index = this.mergingItems.findIndex((x) => x.id === item.id);
      if (index > -1) {
        this.mergingItems.splice(index, 1);
        this.applyIsMerging(this.mergeMode);

        if (this.mergingItems.length <= 1) {
          this.dialogMergeVisible = false;
          this.$alert('Необходимо выбрать 2 или более записей', 'Ошибка', {
            confirmButtonText: 'OK',
            type: 'warning',
            customClass: 'alert-30',
          });
        }
      }
    },
    async handleMerge(data) {
      if (data.secondaryItems.some((x) => x.isAip)) {
        this.$alert('Невозможно объединить несколько корпоративных записей или использовать корпоративную запись в качестве присоединяемой', 'Ошибка', {
          confirmButtonText: 'OK',
          type: 'warning',
          customClass: 'alert-30',
        });
        return;
      }

      if (data.secondaryItems.some((x) => x.organizationTypeId !== data.mainItem.organizationTypeId)) {
        this.$alert('Невозможно объединить организации с разными типами', 'Ошибка', {
          confirmButtonText: 'OK',
          type: 'warning',
          customClass: 'alert-30',
        });
        return;
      }

      this.loading = true;

      const res = await organizationsApi.mergeOrganizations(data.mainItem.id, data.secondaryItems.map((x) => x.id));
      if (!res.data.succeeded) {
        this.$alert(res.data.resultMessage, 'Ошибка', {
          confirmButtonText: 'OK',
          type: 'warning',
          customClass: 'alert-30',
        });
      } else {
        this.dialogMergeVisible = false;
        this.handleMergeModeCancel();
        await this.getOrganizations();
      }
      this.loading = false;
    },
    handleMergeMode() {
      if (!this.mergeMode) {
        this.mergeMode = true;
        // this.applyIsMerging(this.mergeMode);
      } else {
        if (this.mergingItems.length <= 1) {
          this.$alert('Необходимо выбрать 2 или более записей', 'Ошибка', {
            confirmButtonText: 'OK',
            type: 'warning',
            customClass: 'alert-30',
          });
        } else {
          this.dialogMergeVisible = true;
        }
      }
    },
    handleMergeModeCancel() {
      this.mergingItems.splice(0);
      this.mergeMode = false;
      this.applyIsMerging(this.mergeMode);
    }
  }
};
</script>

<style scoped>
.total_items {
   float: right;
   color: #909399;
   font-family: Montserrat-Regular;
   font-size: 14px;
   margin-top: 12px;
   margin-right: 8px;
}
</style>
<style>
  .orgTableColumnNsi .cell {
    display: flex;
    align-items: center;
    padding-left: 16px;
  }
  .orgTableColumnNsi .cell .el-table__expand-icon {
    margin-left: -13px;
    margin-right: -3px;
    width: 16px;
  }
  .orgTableColumnNsi .el-table__placeholder {
    width: 0px;
  }
</style>
